<template>
  <div class="d-flex flex-row">
    <loading-animation
      class="animation"
      v-for="(_, i) in animations"
      :key="i"
      loading="true"
      :animation-name="_"
      :style="widthStyle(i)"
    />
  </div>

</template>

<script>

export default {
  components: {
    LoadingAnimation: () => import('@/components/loading-animation')
  },
  data () {
    return {
      animations: [
        'line-loader',
        'looping-rhombuses-spinner',
        'self-building-square-spinner',
        'circles-to-rhombuses-spinner',
        'scaling-squares-spinner',
        'hollow-dots-spinner'
      ]
    }
  },
  methods: {
    widthStyle (idx) {
      return idx === 0
        ? { width: '300px' }
        : null
    }
  }
}
</script>

<style lang="stylus" scoped>
.animation
  min-width 100px
</style>
